import { combineReducers } from "redux";
import commonReducer from "./commonReducer";
import checkoutReducer from "./checkoutReducer";
import trackingReducer from "./trackingReducer";
import surveyReducer from "./surveyReducer";
import trackingFinanceReducer from "./trackingFinanceReducer";
import trackingCheckoutReducer from "./trackingCheckoutReducer";
import serviceCentersReducer from "./serviceCentersReducer";
import trackingStepReducer from "./trackingStepReducer";

// FIXME: Remove this bad Reducer
var reducers = combineReducers({
  common: commonReducer,
  checkout: checkoutReducer,
  tracking: trackingReducer,
  trackingCheckout: trackingCheckoutReducer,
  trackingFinance: trackingFinanceReducer,
  trackingStep: trackingStepReducer,
  survey: surveyReducer,
  serviceCenters: serviceCentersReducer
});
export default reducers;