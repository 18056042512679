import _objectSpread from "/usr/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { apiUrl, CLIENT_SECRET } from "@globalConfig";
import axios from "axios";
import { loadSentryClient } from "@/utils/asyncHelpers";
import { getUniversalCookieValue, getUserAgent } from "@/utils/GlobalJavascriptFunction";
export var invalidateUser = function invalidateUser() {
  if (typeof window !== "undefined") {
    //alert('Your session has expired, please login again');
    console.log("Your session has expired, please login again");
    window.__authContext__DONT_USE_ME_OR_YOU_WILL_BE_FIRED.logout();
  }
};
var checkResponseCode = function checkResponseCode(response, code) {
  var _response$data;
  return (response === null || response === void 0 ? void 0 : (_response$data = response.data) === null || _response$data === void 0 ? void 0 : _response$data.code) === code || (response === null || response === void 0 ? void 0 : response.status) === code;
};
var shouldInvalidateUser = function shouldInvalidateUser(response) {
  var WHITELIST_PATHS = ['logout', 'customer/list-favourite'];
  var responseStr = JSON.stringify(response);
  return !WHITELIST_PATHS.some(function (path) {
    return responseStr.includes(path);
  });
};
export var is404 = function is404(response) {
  return checkResponseCode(response, 404);
};
export var is230 = function is230(response) {
  return checkResponseCode(response, 230);
};
export var is422 = function is422(response) {
  return checkResponseCode(response, 422);
};
export var isUnauthorized = function isUnauthorized(response) {
  return [401, 403].some(function (code) {
    return checkResponseCode(response, code);
  });
};
export var isNotSuccess = function isNotSuccess(response) {
  var _response$data2, _response$data3;
  return (response === null || response === void 0 ? void 0 : response.status) >= 400 || (response === null || response === void 0 ? void 0 : (_response$data2 = response.data) === null || _response$data2 === void 0 ? void 0 : _response$data2.code) >= 400 || (response === null || response === void 0 ? void 0 : (_response$data3 = response.data) === null || _response$data3 === void 0 ? void 0 : _response$data3.success) === false;
};
export var extractLangFromReq = function extractLangFromReq(req) {
  if (__IS_CSR__) {
    return window.location.pathname.match(/^(\/en)/) ? "en" : "ar";
  }
  return req ? "".concat(req.language) : "ar";
};
export var createAxiosInstance = function createAxiosInstance(req) {
  var _req$headers, _req$headers2, _req$headers3;
  var basePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'syarah_v1';
  var isJsonContentType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var token = getUniversalCookieValue(req, "accessToken");
  var UUID = getUniversalCookieValue(req, "UUID", "UA-" + getUserAgent(req));
  var lang = extractLangFromReq(req);
  var baseURL = "".concat(apiUrl.replace('syarah_v1', basePath), "/").concat(lang);
  // get Ip address from express request, or cloudflare headers
  var remoteIp = (req === null || req === void 0 ? void 0 : (_req$headers = req.headers) === null || _req$headers === void 0 ? void 0 : _req$headers["cf-connecting-ip"]) || (req === null || req === void 0 ? void 0 : (_req$headers2 = req.headers) === null || _req$headers2 === void 0 ? void 0 : _req$headers2["x-forwarded-for"]) || (req === null || req === void 0 ? void 0 : (_req$headers3 = req.headers) === null || _req$headers3 === void 0 ? void 0 : _req$headers3["x-real-ip"]) || null;
  var headers = _objectSpread(_objectSpread({}, !token ? "" : {
    Authorization: "Bearer ".concat(token)
  }), {}, {
    token: CLIENT_SECRET,
    "Content-Type": isJsonContentType ? "application/json" : "application/x-www-form-urlencoded",
    Accept: "application/json",
    "user-id": UUID,
    device: "web",
    "Accept-EnhancedStatusCodes": 1
  }, remoteIp ? {
    "x-secret-forwarded-ip": remoteIp
  } : {});
  var axiosInstance = axios.create({
    baseURL: baseURL,
    headers: headers
  });
  return axiosInstance;
};

/**
 *
 * @param {import('express').Request} [req] - The optional Express request object
 * @param isJsonContentType
 * @returns {import('axios').AxiosInstance}
 */
var createFetcher = function createFetcher() {
  var req = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var isJsonContentType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (req && req.axiosInstance) {
    return req.axiosInstance;
  }
  var axiosInstance = createAxiosInstance(req, "syarah_v1", isJsonContentType);
  axiosInstance.interceptors.response.use(function (response) {
    if (isNotSuccess(response)) {
      var _response$config;
      // Log the full response to Sentry
      var errorMessage = "Non-200 application code received: ".concat((response === null || response === void 0 ? void 0 : (_response$config = response.config) === null || _response$config === void 0 ? void 0 : _response$config.url) || "URL");
      loadSentryClient(req).then(function (senInstance) {
        if (!is230(response) && !is404(response) && senInstance) {
          senInstance.withScope(function (scope) {
            var _response$config2, _response$config3, _response$config4, _response$config5, _response$data4, _response$data5, _response$data6;
            scope.setExtra("api", "".concat((response === null || response === void 0 ? void 0 : (_response$config2 = response.config) === null || _response$config2 === void 0 ? void 0 : _response$config2.method) || "METHOD", " ").concat((response === null || response === void 0 ? void 0 : (_response$config3 = response.config) === null || _response$config3 === void 0 ? void 0 : _response$config3.url) || "URL"));
            scope.setExtra("body", response === null || response === void 0 ? void 0 : (_response$config4 = response.config) === null || _response$config4 === void 0 ? void 0 : _response$config4.data);
            scope.setExtra("request_headers", response === null || response === void 0 ? void 0 : (_response$config5 = response.config) === null || _response$config5 === void 0 ? void 0 : _response$config5.headers);
            scope.setExtra("response_headers", response === null || response === void 0 ? void 0 : response.headers);
            scope.setExtra("response_body", (response === null || response === void 0 ? void 0 : response.data) || {});
            scope.setExtra("status_code", response === null || response === void 0 ? void 0 : (_response$data4 = response.data) === null || _response$data4 === void 0 ? void 0 : _response$data4.code);
            scope.setExtra("http_status_code", response === null || response === void 0 ? void 0 : response.status);
            scope.setExtra("validation_errors", response === null || response === void 0 ? void 0 : (_response$data5 = response.data) === null || _response$data5 === void 0 ? void 0 : _response$data5.validation_errors);
            scope.setExtra("error", response === null || response === void 0 ? void 0 : (_response$data6 = response.data) === null || _response$data6 === void 0 ? void 0 : _response$data6.error);
            senInstance.captureException(new Error(errorMessage));
          });
        }
      });
    }
    if (isUnauthorized(response) && shouldInvalidateUser(response)) {
      invalidateUser();
    }
    return response;
  }, function (error) {
    var _error$response, _error$response$data;
    console.error("-> error", error);
    if (isUnauthorized(error === null || error === void 0 ? void 0 : error.response) && shouldInvalidateUser(error === null || error === void 0 ? void 0 : error.response)) {
      invalidateUser();
    }
    // just in case the status code is not 230!
    if (error !== null && error !== void 0 && (_error$response = error.response) !== null && _error$response !== void 0 && (_error$response$data = _error$response.data) !== null && _error$response$data !== void 0 && _error$response$data.validation_errors) {
      var _error$response2, _error$response2$data;
      var errorMessagesArray = Object.values((error === null || error === void 0 ? void 0 : (_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : (_error$response2$data = _error$response2.data) === null || _error$response2$data === void 0 ? void 0 : _error$response2$data.validation_errors) || {});
      if (Array.isArray(errorMessagesArray) && errorMessagesArray.length > 0) {
        var message = errorMessagesArray.join("\n ");
        return Promise.reject({
          message: message
        });
      } else {
        var _error$response3, _error$response3$data;
        var _message = JSON.stringify((error === null || error === void 0 ? void 0 : (_error$response3 = error.response) === null || _error$response3 === void 0 ? void 0 : (_error$response3$data = _error$response3.data) === null || _error$response3$data === void 0 ? void 0 : _error$response3$data.validation_errors) || {});
        return Promise.reject({
          message: _message
        });
      }
    }
    if (req !== null && req !== void 0 && req.Sentry && !is404(error === null || error === void 0 ? void 0 : error.response)) {
      // Create a customized error message to capture
      var errorMessage = "Axios request failed: ";
      if (error !== null && error !== void 0 && error.response) {
        var _error$response4, _error$response5, _error$response5$conf, _error$response6, _error$response6$conf, _error$response7;
        errorMessage += "Status code: ".concat(error === null || error === void 0 ? void 0 : (_error$response4 = error.response) === null || _error$response4 === void 0 ? void 0 : _error$response4.status, ", URL: ").concat(error === null || error === void 0 ? void 0 : (_error$response5 = error.response) === null || _error$response5 === void 0 ? void 0 : (_error$response5$conf = _error$response5.config) === null || _error$response5$conf === void 0 ? void 0 : _error$response5$conf.url, ", Params: ").concat(JSON.stringify(error === null || error === void 0 ? void 0 : (_error$response6 = error.response) === null || _error$response6 === void 0 ? void 0 : (_error$response6$conf = _error$response6.config) === null || _error$response6$conf === void 0 ? void 0 : _error$response6$conf.params), ", Data: ").concat(JSON.stringify(error === null || error === void 0 ? void 0 : (_error$response7 = error.response) === null || _error$response7 === void 0 ? void 0 : _error$response7.data));
      } else if (error !== null && error !== void 0 && error.request) {
        var _error$request, _error$request2;
        errorMessage += "Request made but no response received, URL: ".concat(error === null || error === void 0 ? void 0 : (_error$request = error.request) === null || _error$request === void 0 ? void 0 : _error$request.url, ", Params: ").concat(JSON.stringify(error === null || error === void 0 ? void 0 : (_error$request2 = error.request) === null || _error$request2 === void 0 ? void 0 : _error$request2.params));
      } else {
        errorMessage += (error === null || error === void 0 ? void 0 : error.message) || "Axios Error: unknown";
      }

      // Capture the exception in Sentry
      req.Sentry.captureException(new Error(errorMessage));
    }
    // on server side, we need to reject the promise
    if (req) {
      return Promise.reject(error);
    }
    return Promise.resolve(error);
  });
  if (req && req !== null && req !== void 0 && req.axiosInstance) {
    req.axiosInstance = axiosInstance;
  }
  return axiosInstance;
};
export default createFetcher;