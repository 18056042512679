import {
  GET_ORDER_DETAILS,
  CREATE_ORDER,
  REFERRAL_ORDER,
  UPDATEFIELDS,
  CREATE_ORDER_ERROR,
  RESET_CHECKOUT_FIELDS,
  GET_REMAINING_ORDER_DETAILS,
  SADAD_INFORMATION,
  SADAD_ERROR,
  GET_FINANCE_REMAINING_ORDER_DETAILS, GET_PAYLINK_ORDER_DETAILS,
} from "../types";

const initialState = {
  // remainingOrder: null,
  remainingOrder: {
    id: 0,
    customer: {},
    post_title: "",
    post_url: "",
    post_id: 0,
    post_image: "",
    post_is_new: 0,
    total_price: "",
    remaining_price_payed: "",
    payed_amount: "",
    paymentOptions: {},
    post_price: "",
    referral_code: "",
    referral_discount: 0,
    registration_fees: "",
    remaining_price: "",
    reserved_status: 1,
    response_code: "",
    response_message: "",
    shipping_fees: "",
    shipping_fees_add_text: "",
    arboun: "",
    city_id: 0,
    deposit_payable: 1,
    discounts: {},
    vat: "",
    vat_note: "",
    warrantyPriceDetails: {},
    story_section: {},
    g4_data_layer: {},
    is_deleted: false,
    is_expired: false,
    is_sold: false,
    is_warranty_10_day: true,
    limited_quantity: false,
    date: "",
  },
  hasSadad: null,
  sadad: { is_enabled: false, invoice_type: "", invoices: [] },
  sadadError: "",
  order: null,
  orderCreated: null,
  referralData: null,
  create_order_errors: {},
  updatedValues: {
    whatsapp_optin: 1,
  },
  financeRemainingOrder: {
    order_id: 0,
    customer_name: "",
    post_title: "",
    post_image: "",
    total_amount: "",
    remaining_amount: "",
    paid_amount: "",
    service_amount: "",
    vas_amount: "",
    paymentOptions: {},
    date: "",
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ORDER_DETAILS:
      return { ...state, order: payload };
    case GET_REMAINING_ORDER_DETAILS:
      return {
        ...state,
        remainingOrder: payload,
        sadad: payload.paymentOptions.sadad,
        hasSadad: payload.paymentOptions.sadad.is_enabled,
      };
    case SADAD_INFORMATION:
      let order;
      if (!state.remainingOrder) {
        order = state.financeRemainingOrder;
      } else {
        order = state.remainingOrder;
      }
      order.paymentOptions.sadad = payload;
      return {
        ...state,
        sadad: payload,
      };
    case SADAD_ERROR:
      return {
        ...state,
        sadadError: payload,
      };
    case CREATE_ORDER:
      return { ...state, orderCreated: payload };
    case REFERRAL_ORDER:
      return { ...state, referralData: payload };
    case UPDATEFIELDS:
      return {
        ...state,
        updatedValues: {
          ...state.updatedValues,
          [payload.key]: payload.value,
        },
      };

    case RESET_CHECKOUT_FIELDS:
      return {
        ...state,
        updatedValues: initialState.updatedValues,
        referralData: null,
      };
    case CREATE_ORDER_ERROR:
      return { ...state, create_order_errors: payload };
    case GET_FINANCE_REMAINING_ORDER_DETAILS:
      return {
        ...state,
        financeRemainingOrder: payload,
        sadad: payload.paymentOptions.sadad,
        hasSadad: payload.paymentOptions.sadad.is_enabled,
      };
    case GET_PAYLINK_ORDER_DETAILS:
      return {
        ...state,
        remainingOrder: payload,
        sadad: payload.paymentOptions.sadad,
        hasSadad: payload.paymentOptions.sadad.is_enabled,
      }
    default:
      return state;
  }
};
