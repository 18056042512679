import _slicedToArray from "/usr/app/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import cookieClient from "react-cookies/build/cookie";
import useBoolean from "@hooks/useBoolean";
import { useCallback, useEffect } from "react";
import { trackEvents } from "@/common/events/events";
import useActionCookie from "@hooks/useActionCookie";
import { NOTIFICATIONS_INTEREST_CONFIG } from "@globalConfig";
import { useSession } from "@/_MiniApps/auth/AuthContext";
var COOKIE_NAME = "notification_interest";
var useNotificationInterests = function useNotificationInterests(postId, isFavorite) {
  var _useSession = useSession(),
    isLoggedIn = _useSession.isLoggedIn,
    userData = _useSession.user;
  var _useBoolean = useBoolean(),
    whatsModalOpen = _useBoolean.value,
    openWhatsModal = _useBoolean.setTrue,
    closeWhatsModal = _useBoolean.setFalse;
  var _NOTIFICATIONS_INTERE = NOTIFICATIONS_INTEREST_CONFIG.split("|").map(function (s) {
      return parseInt(s);
    }),
    _NOTIFICATIONS_INTERE2 = _slicedToArray(_NOTIFICATIONS_INTERE, 2),
    maxUsages_config = _NOTIFICATIONS_INTERE2[0],
    numDays_configs = _NOTIFICATIONS_INTERE2[1];
  var _useActionCookie = useActionCookie({
      cookieName: COOKIE_NAME,
      maxUsages: maxUsages_config || 3,
      numDays: numDays_configs || 1
    }),
    _useActionCookie2 = _slicedToArray(_useActionCookie, 2),
    canShowModal = _useActionCookie2[0],
    increaseUsage = _useActionCookie2[1];
  var tryOpenNotificationModal = useCallback(function () {
    var opt_whatsapp_interest = (userData === null || userData === void 0 ? void 0 : userData.opt_whatsapp_interest) || (userData === null || userData === void 0 ? void 0 : userData.opt_whatsapp_interest);
    if (!opt_whatsapp_interest && canShowModal) {
      increaseUsage();
      openWhatsModal();
    }
  }, [userData === null || userData === void 0 ? void 0 : userData.opt_whatsapp_interest, canShowModal]);
  useEffect(function () {
    if (isLoggedIn && postId && window.sessionStorage.getItem('favorite_post_id') === (postId === null || postId === void 0 ? void 0 : postId.toString())) {
      window.sessionStorage.removeItem('favorite_post_id');
      tryOpenNotificationModal();
    }
  }, [isLoggedIn, postId, isFavorite]);
  useEffect(function () {
    if (whatsModalOpen) {
      var uuid = cookieClient.load("UUID") || null;
      trackEvents("notification_popup_shown", {
        uuid: uuid,
        user_id: userData.phone_number
      });
    }
  }, [whatsModalOpen, userData]);
  return {
    tryOpenNotificationModal: tryOpenNotificationModal,
    whatsModalOpen: whatsModalOpen,
    closeWhatsModal: closeWhatsModal
  };
};
export default useNotificationInterests;