import { Link } from "react-router-dom";
import stl from "./SuggestionsContainer.module.css";
import UnbxdCard from "../../../components/SearchComponent/UnbxdComponents/UnbxdCard/UnbxdCard";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
import { thumbUrl } from "@/utils/imagesHelpers";
import useIsMobile from "@hooks/useIsMobile";
import useHelpers from "@hooks/useHelpers";

const SuggestionsContainerOnline = (props) => {
  const isMobile = useIsMobile();
  const { createUrl } = useHelpers();
  return (
    <>
      <div className={stl.SuggestionsContainerOnline}>
        <div className={["container", stl.revampedHome].join(" ")}>
          <h2 className={["big", stl.revampedTitle].join(" ")}>
            {props.title}
          </h2>

          <div
            className={[stl.allCarsResult, stl.allCarsResultRevamped].join(" ")}
          >
            {props.similarPosts.map((post, i) => {
              return (
                <UnbxdCard
                  isEager={false}
                  imageSize="small"
                  key={post.id}
                  post={post}
                  rank={i}
                  page={1}
                  fromHomePage={true}
                  showGrantedPopup={() => { }}
                  isOnline={true}
                  hideFavIcon
                  hideGrantedLabel
                  hideFooterDeskMob
                  className={stl.postCard}
                  hideMarkitingLabel
                  hideDiscountLabel
                  TheImage={
                    !isMobile
                      ? null
                      : () => (
                        <ProgressiveImage
                          waitUntilInteractive
                          alt={""}
                          src={thumbUrl(
                            post?.image_url,
                            "online-v1",
                            "0x154"
                          )}
                          loading="lazy"
                          fetchpriority="low"
                          width={110}
                          height={125}
                        />
                      )
                  }
                  CustomTag={"h3"}
                />
              );
            })}
          </div>
          <Link
            to={createUrl(
              "/filters",
              {
                ["condition_id"]: props.isNew ? 1 : 0,
              },
              {
                referral_location: "Home",
                browse_variant: "view_all",
                variant_value: "view all",
              }
            )}
            state={{ search_type: "homepage_listing" }}
            className={[
              ["redBtn", "moreSameCar", stl.moreSameCar, stl.showMorwBtn].join(" "),
            ].join(" ")}
          >
            {props.btnTxt}
          </Link>
        </div>
      </div>
    </>
  );
};

export default SuggestionsContainerOnline;
