import {trackEvents} from "@/common/events/events";
import {serverUrl} from "@globalConfig";


export async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
        //secure origin (HTTPS)
        return await navigator.clipboard.writeText(text);
    } else {
        //NOT secure origin (HTTP)
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand("copy");
        } catch (err) {
            console.error("Unable to copy to clipboard", err);
        }
        document.body.removeChild(textArea);
    }
}

export function getNormalizePathName(url, lowerCase) {
    url = url.split("?")[0].replace("/en/", "/").trim();
    url = url
        .split("_")
        .filter(Boolean)
        .join("_")
        .replaceAll("_-", "-")
        .replaceAll("-_", "-")
        .substring(1);

    url = lowerCase ? url.toLowerCase() : url;
    return decodeURIComponent(url);
}

export function getLocalizedDate(date, language, isMonthInNumbers = false, implodeDateBy = "/") {
    const day = new Intl.DateTimeFormat(language, {weekday: "long"}).format(
        date
    );
    const monthinAlphabet = new Intl.DateTimeFormat(language, {
        month: "long",
    }).format(date);
    const year = date.getFullYear();
    const dayOfMonth = date.getDate();
    const monthInNumbers = date.getMonth() + 1; // Adjusting month to start from 1
    const formattedMonth = isMonthInNumbers
        ? monthInNumbers.toString().padStart(2, "0")
        : monthinAlphabet;

    return {day: day, date: `${dayOfMonth} ${implodeDateBy} ${formattedMonth} ${implodeDateBy} ${year}`};
}

export function getFormatTime(date, language = "ar") {
    // Extract hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Determine whether it's morning or evening
    const periodOfDay = hours < 12 ? "صباحا" : "مساءا";

    // Convert to 12-hour format
    const twelveHourFormat = hours >= 12 ? "PM" : "AM";

    // Format the hours
    const formattedHours = hours % 12 || 12; // Handle midnight (12 AM)

    return {
        time: `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes}`,
        format: `${language === "ar" ? periodOfDay : twelveHourFormat}`,
    };
}

export const handleHasAccountEvent = (
    {
        hasAccount,
        status,
        phone_number,
        lang,
        city_name = null,
        user_name = null,
        referral_location = "direct_account",
    }) => {
    if (hasAccount) {
        trackEvents("user_login", {
            user_login_status: status,
            user_id: `966${phone_number?.slice(1)}`,
            ui_language: lang.toUpperCase(),
            referral_location: referral_location,
        });
    } else {
        trackEvents("user_signup", {
            user_id: `966${phone_number?.slice(1)}`,
            ui_language: lang.toUpperCase(),
            user_signup_status: status,
            user_city: city_name,
            user_name: user_name,
            referral_location: referral_location,
        });
    }
};



export function isNoIndexFollow(queryObj, isPagination) {
    const queryKeys = Object.keys(queryObj);
    return queryKeys.length > 0 && !(queryKeys.length === 1 && queryKeys[0] === 'page' && isPagination)
}




export function canonicalize(url, removeAllParams = false) {
    if (!url) return "";
    const excludedParams = ['sol', 'page', 'sort'];
    if (!url.startsWith('http')) {
        url = serverUrl + url;
    }
    try {
        const urlObj = new URL(url);
        let keysToRemove = [];

        // Remove all query parameters except for the excluded ones
        urlObj.searchParams.forEach((value, key) => {
            if (!excludedParams.includes(key) || removeAllParams) {
                keysToRemove.push(key);
            }
        });
        keysToRemove.forEach((value, key) => {
            urlObj.searchParams.delete(value);
        });

        return urlObj.toString().replace(/\/$/, "");//remove trailing slash
    } catch (error) {
        console.error('Invalid URL:', error);
        return null;
    }
}
