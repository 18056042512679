import { useLang } from "@/common/Contexts/LanguageContext";
import { SHOW_DEBUG_INFO } from "@globalConfig";
import CustomToast from "@componentsShared/CustomToast";

export const events = {
  view_post: [
    "post_id",
    "is_finance",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_exterior_color",
    "post_fuel",
    "post_transmission",
    "post_cabin",
    "post_cylinder",
    "post_mileage",
    "post_drivetrain",
    "post_engine_size",
    "post_price",
    "marketing_theme",
    "logged_in",
    "listing_type",
    "product_position",
    "page_number",
    "is_rec",
    "post_discount"
  ],
  compare_car_select: [
    "user_id",
    "post_id",
    "referral_location",
    "post_make",
    "post_model",
    "post_year",
  ],
  comparison_start: [
    "user_id",
    "referral_location",
    "post_1_id",
    "post_1_make",
    "post_1_model",
    "post_1_year",
    "post_1_condition",
    "post_2_id",
    "post_2_make",
    "post_2_model",
    "post_2_year",
    "post_2_condition"
  ],
  video_displayed: ["page_location"],
  video_closed: ["page_location"],
  view_inspection: ["post_id"],
  view_image: ["post_id"],
  view_video: ["post_id"],
  view_360: ["post_id"],
  usp_view: ["post_id", "usp_type"],
  post_share: ["post_id", "share_medium"],
  add_favorite: ["post_id"],
  online_contact: ["post_id", "contact_type", "contact_location"],
  element_click: ["element_name", "element_location"],
  inspection_image_view: ["post_id", "main_section", "part_name"],
  inspection_share: ["post_id", "share_medium"],
  checkout: [
    "booking_type",
    "post_id",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_exterior_color",
    "post_price",
    "from_finance_calculator",
  ],
  survey_start: [
    "user_id",
    "survey_type",
    "order_id",
    "order_type",
    "post_condition",
  ],
  survey_progress: [
    "user_id",
    "survey_type",
    "order_id",
    "question_order",
    "order_type",
    "post_condition",
  ],
  survey_submit: [
    "user_id",
    "survey_type",
    "order_id",
    "order_type",
    "post_condition",
    "csat_score",
  ],
  order_document_upload: [
    "order_id",
    "order_type",
    "post_condition",
    "document_id_type",
    "status",
    "platform",
  ],
  order_document_download: [
    "order_id",
    "order_type",
    "post_condition",
    "document_id_type",
  ],
  user_signup: [
    "user_signup_status",
    "user_id",
    "ui_language",
    "user_city",
    "user_name",
    "referral_location",
  ],
  user_login: [
    "user_login_status",
    "user_id",
    "ui_language",
    "referral_location",
  ],
  user_logout: ["user_id", "ui_language"],
  sell_car_started: ["referral_location", "platform"],
  sell_car_form_landing: ["platform"],
  sell_car_cities_far: [
    "car_make",
    "car_model",
    "car_trim",
    "car_year",
    "car_odometer_range",
    "car_status",
    "car_color",
    "ownership_type",
    "car_city",
    "platform",
  ],
  sell_car_location_select: [
    "car_make",
    "car_model",
    "car_trim",
    "car_year",
    "car_odometer_range",
    "car_status",
    "car_color",
    "ownership_type",
    "car_city",
    "inspection_city",
    "estimated_price",
    "suggested_price",
    "inspection_location",
    "platform",
  ],
  sell_car_price_suggestion_submit: [
    "car_make",
    "car_model",
    "car_trim",
    "car_year",
    "car_odometer_range",
    "car_status",
    "car_color",
    "ownership_type",
    "car_city",
    "inspection_city",
    "estimated_price",
    "suggested_price",
    "platform",
  ],
  sell_car_price_accept: [
    "car_make",
    "car_model",
    "car_trim",
    "car_year",
    "car_odometer_range",
    "car_status",
    "car_color",
    "ownership_type",
    "car_city",
    "inspection_city",
    "estimated_price",
    "platform",
  ],
  sell_car_appointment_book: [
    "car_make",
    "car_model",
    "car_trim",
    "car_year",
    "car_odometer_range",
    "car_status",
    "car_color",
    "ownership_type",
    "car_city",
    "inspection_city",
    "estimated_price",
    "suggested_price",
    "payment_option",
    "inspection_location",
    "payment_status",
    "platform",
  ],
  sell_car_details_submit: [
    "car_make",
    "car_model",
    "car_trim",
    "car_year",
    "car_odometer_range",
    "car_status",
    "car_color",
    "ownership_type",
    "platform",
  ],
  sell_car_contact_submit: [
    "car_make",
    "car_model",
    "car_trim",
    "car_year",
    "car_odometer_range",
    "car_color",
    "car_city",
    "inspection_city",
    "car_status",
    "status",
    "ownership_type",
    "fail_reason",
    "platform",
  ],
  book_car: [
    "post_id",
    "is_finance",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_exterior_color",
    "post_price",
    "marketing_theme",
  ],
  cash_form_submit: [
    "order_id",
    "post_id",
    "user_city",
    "discount_code",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_exterior_color",
    "post_price",
    "total_amount",
    "marketing_theme",
    "from_car_comparison"
  ],
  cash_order_submit: [
    "post_id",
    "order_id",
    "user_id",
    "payment_option",
    "response_code",
    "response_message",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_exterior_color",
    "post_price",
    "marketing_theme",
    "payment_status",
    "total_amount",
    "discount_code",
    "from_car_comparison"
  ],
  finance_form_submit: [
    "order_id",
    "post_id",
    "post_price",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_exterior_color",
    "salary",
    "salary_transfer",
    "status",
    "from_finance_calculator",
    "from_car_comparison",
  ],
  finance_order_submit: [
    "post_id",
    "order_id",
    "user_id",
    "payment_option",
    "payment_status",
    "response_code",
    "response_message",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_exterior_color",
    "post_price",
    "from_finance_calculator",
    "from_car_comparison"
  ],
  search: ["search_query", "interaction_type", "results_count", "page_number"],
  filter_search: [
    "filter_name",
    "filter_value",
    "results_count",
    "page_number",
  ],
  search_impression: [
    "search_query",
    // "filter_name",
    // "filter_value",
    "page_number",
    "results_count",
  ],
  browse_impression: ["category_path", "page_number", "results_count"],
  product_click: [
    "search_query",
    "listing_type",
    "product_position",
    "page_number",
  ],
  browse_cars: [
    "referral_location",
    "browse_variant",
    "variant_value",
    "page_number",
    "results_count",
  ],
  marketing_theme_click: [
    "post_id",
    "post_condition",
    "theme_type",
    "marketing_theme",
    "referral_location",
    "has_timer",
  ],

  validation_error: ["document_id_type", "error_description"],
  finance_calculator_open: [
    "post_id",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_price",
  ],
  finance_calculator_interact: [
    "field_interaction",
    "post_id",
    "post_price",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_exterior_color",
  ],
  recommendation_section_appeared: [
    "uuid",
    "user_id",
    "referral_location",
    "results_count",
  ],
  notification_popup_shown: ["uuid", "user_id"],
  notification_popup_response: ["uuid", "user_id", "popup_response"],
  notification_whatsapp_open: ["uuid", "user_id"],
  recommendation_listing_page_viewed: ["uuid", "user_id", "referral_location"],
  recommendation_car_clicked: [
    "uuid",
    "user_id",
    "post_id",
    "referral_location",
  ],
  notification_whatsapp_unsubscribe: ["uuid", "user_id"],
  comparison_tab_view: [
    "user_id",
    "tab_name",
    "referral_location",
    "post_1_id",
    "post_1_make",
    "post_1_model",
    "post_1_year",
    "post_1_condition",
    "post_2_id",
    "post_2_make",
    "post_2_model",
    "post_2_year",
    "post_2_condition",
  ],
  comparison_book_car: [
    "user_id",
    "post_id",
    "post_make",
    "post_model",
    "post_year",
  ],
  return_button_click:[
      "user_id",
      "order_id"
  ],
  return_request_form_start:[
    "user_id",
    "order_id"
  ],
  return_request_submit:[
    "user_id",
    "order_id",
    "return_reason",
    "original_delivery_city",
    "customer_city",
    "odometer_photo_upload",
    "issue_photos_upload",
    "whatsapp_opt_in",
  ],
};

if (__IS_CSR__) {
  window.lastEvent = null;
}

export const trackEvents = (event, data) => {
  if (window.lastEvent === event) {
    return;
  }
  window.lastEvent = event;
  setTimeout(() => {
    window.lastEvent = null;
  }, 500);

  const params = {};

  if (events[event]) {
    events[event].forEach((element) => {
      if (data[element] || typeof data[element] === "boolean") {
        params[element] = data[element];
      }
    });
    if (__IS_CSR__) {
      const user_country = localStorage.getItem("country_code");
      if (user_country) {
        params.user_country = user_country;
      }
      params.language = useLang();
    }

    if (SHOW_DEBUG_INFO) {
      const options = {
        duration: 5000,
        position: "bottom-left",
        style: {
          direction: "ltr",
          border: "0",
          padding: "0",
          color: "transparent",
        },
      };
      window?.toast(
        (t) => {
          const paramsCopy = { ...params };
          // delete user_country,language
          delete paramsCopy.user_country;
          delete paramsCopy.language;
          // loop through the params and substring to max 50 char
          for (const key in paramsCopy) {
            if (paramsCopy.hasOwnProperty(key)) {
              const element = paramsCopy[key];
              if (typeof element === "string") {
                paramsCopy[key] = element.substring(0, 20);
              }
            }
          }
          return (
            <div style={{ direction: "ltr" }}>
              <CustomToast
                title={event}
                message={JSON.stringify(paramsCopy, null, 2)}
                onClose={() => toast.dismiss(t.id)}
              />
            </div>
          );
        },
        {
          duration: 100000000,
          position: "bottom-left",
          style: {
            direction: "ltr",
            border: "0",
            padding: "0",
            color: "transparent",
            margin: "0",
          },
        }
      );
    }
    console.log(event, JSON.stringify(params));

    PushDataLayerObject({
      event: event,
      ...params,
      ...window?.dataLayerMeta,
    });
  }
};
