import arWebsiteSettings from "../../../data/website-settings/ar.json";
import enWebsiteSettings from "../../../data/website-settings/en.json";
import all_flags from "../../../data/feature-flags/all.json";
import cookieClient from "react-cookies";
export function websiteConfig(lang) {
  if (lang === "ar") {
    return arWebsiteSettings;
  }
  return enWebsiteSettings;
}
export function checkFeatureFlag(flag) {
  if (__IS_CSR__ && window.location.href.includes(flag)) {
    return true;
  }
  if (__IS_CSR__ && localStorage.getItem(flag)) {
    return true;
  }
  if (__IS_CSR__ && cookieClient.load(flag)) {
    return true;
  }
  return all_flags[flag] || false;
}