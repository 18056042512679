import { parseJsonSafely } from "@/utils/GlobalJavascriptFunction";
import { useEffect } from "react";
var LOCAL_STORAGE_LAST_POSITION_KEY = "last_position";
var LOCAL_STORAGE_SEARCH_RES_KEY = "last_search_results";
var isAllowedPagePath = function isAllowedPagePath(allowed_page_path) {
  if (__IS_SSR__) return false;
  return !!window.prevPath && window.prevPath.includes(allowed_page_path);
};
var cleanLastPosition = function cleanLastPosition() {
  if (__IS_SSR__) return;
  sessionStorage.removeItem(LOCAL_STORAGE_LAST_POSITION_KEY);
};
var cleanLastStorageData = function cleanLastStorageData() {
  if (__IS_SSR__) return;
  sessionStorage.removeItem(LOCAL_STORAGE_SEARCH_RES_KEY);
};
var preserveData = function preserveData(searchResultsData) {
  sessionStorage.setItem(LOCAL_STORAGE_SEARCH_RES_KEY, JSON.stringify(searchResultsData));
};
var preserveScrollPosition = function preserveScrollPosition() {
  sessionStorage.setItem(LOCAL_STORAGE_LAST_POSITION_KEY, window.scrollY.toString());
};
var usePreserveData = function usePreserveData(allowed_page_path) {
  var lastScrollPosition = 0;
  var lastSearchResultsData = null;
  if (isAllowedPagePath(allowed_page_path)) {
    lastScrollPosition = Number(sessionStorage.getItem(LOCAL_STORAGE_LAST_POSITION_KEY));
    lastSearchResultsData = parseJsonSafely(sessionStorage.getItem(LOCAL_STORAGE_SEARCH_RES_KEY));
  }
  cleanLastPosition(); // Clean the value after using it

  return {
    preserveData: preserveData,
    preserveScrollPosition: preserveScrollPosition,
    lastScrollPosition: Number(lastScrollPosition),
    lastSearchResultsData: lastSearchResultsData
  };
};
export var useMaintainSearchResultsDataStorage = function useMaintainSearchResultsDataStorage(supportedBasePathNames) {
  var _window, _window2, _window2$location;
  if (__IS_SSR__) {
    return;
  }
  var pathname = (_window = window) === null || _window === void 0 ? void 0 : _window.location.pathname;
  useEffect(function () {
    // check the current pathname if it starts with any of the supportedBasePathNames
    var isSupportedPath = supportedBasePathNames.some(function (basePathName) {
      return pathname.includes(basePathName);
    });
    if (!isSupportedPath) {
      cleanLastPosition();
      cleanLastStorageData();
    }
  }, [(_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$location = _window2.location) === null || _window2$location === void 0 ? void 0 : _window2$location.href]);
};
export default usePreserveData;