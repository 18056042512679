import _slicedToArray from "/usr/app/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import { JWT_EXPIRE_TIME_IN_MINUTES, SYARAH_V2_JWT_SECRET } from "@globalConfig";
import SHA256 from 'crypto-js/sha256';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import Hex from 'crypto-js/enc-hex';
import Utf8 from 'crypto-js/enc-utf8';
export function createToken(token) {
  var header = {
    typ: 'JWT',
    alg: 'HS256'
  };
  if (!token.key) {
    token.key = SYARAH_V2_JWT_SECRET;
  }
  var adjustSeconds = parseFloat(localStorage.getItem('serverTimeDiff') || '0');
  var segments = [];
  var iat = Math.floor(Date.now() / 1000) + adjustSeconds;
  var exp = iat + 60 * JWT_EXPIRE_TIME_IN_MINUTES;
  segments.push(btoa(JSON.stringify(header)).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_'));
  segments.push(btoa(JSON.stringify({
    aud: token.audience,
    sub: token.sub,
    access_token: token.access_token,
    iat: iat,
    payload: payloadHash(token.data),
    exp: exp
  })).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_'));
  var footer = HMAC_SIGN(segments.join('.'), token.key);
  segments.push(footer);
  return segments.join('.');
}
function HMAC_SIGN(data, key) {
  try {
    if (!key || typeof key !== 'string' || key.trim() === '') {
      throw new Error("HMAC key must be a non-empty string.");
    }

    // Generate the HMAC signature using SHA-256
    var hash = HmacSHA256(data, key);

    // Encode the hash in base64 and convert it to a URL-safe format
    var base64Signature = Base64.stringify(hash).replace(/=/g, '') // Remove padding
    .replace(/\+/g, '-') // Replace '+' with '-'
    .replace(/\//g, '_'); // Replace '/' with '_'

    return base64Signature;
  } catch (error) {
    console.error("Error signing data:", error);
    throw new Error("Failed to sign data");
  }
}
function payloadHash(data) {
  try {
    // Convert the data to a string and hash it using SHA-256
    var dataString = JSON.stringify(data);
    var hash = SHA256(dataString);

    // Convert the hash to a hexadecimal string representation
    return hash.toString(Hex);
  } catch (error) {
    console.error("Error hashing payload:", error);
    throw new Error("Failed to hash payload");
  }
}
export function validateToken(token) {
  try {
    // Split the token into its components
    var segments = token.split('.');
    if (segments.length !== 3) {
      return {
        isValid: false,
        error: 'Invalid token format'
      };
    }
    var _segments = _slicedToArray(segments, 3),
      encodedHeader = _segments[0],
      encodedPayload = _segments[1],
      signature = _segments[2];

    // Decode and parse header
    var headerJson = Base64.parse(base64UrlUnescape(encodedHeader)).toString(Utf8);
    var header = JSON.parse(headerJson);

    // Validate header
    if (header.typ !== 'JWT' || header.alg !== 'HS256') {
      return {
        isValid: false,
        error: 'Invalid token header'
      };
    }

    // Decode and parse payload
    var payloadJson = Base64.parse(base64UrlUnescape(encodedPayload)).toString(Utf8);
    var payload = JSON.parse(payloadJson);

    // Check token expiration
    var currentTime = Math.floor(Date.now() / 1000) + parseFloat(localStorage.getItem('serverTimeDiff') || '0');
    if (currentTime > payload.exp) {
      return {
        isValid: false,
        error: 'Token has expired'
      };
    }

    // Verify signature
    var signatureData = "".concat(encodedHeader, ".").concat(encodedPayload);
    var computedSignature = HMAC_SIGN(signatureData, SYARAH_V2_JWT_SECRET);
    if (computedSignature !== signature) {
      return {
        isValid: false,
        error: 'Invalid signature'
      };
    }
    return {
      isValid: true,
      payload: payload
    };
  } catch (error) {
    console.error('Token validation error:', error);
    return {
      isValid: false,
      error: error instanceof Error ? error.message : 'Unknown validation error'
    };
  }
}
export var validateJwtPayloadValue = function validateJwtPayloadValue(token, key, value) {
  var validationResult = validateToken(token);
  if (validationResult.isValid) {
    return validationResult.payload[key] === value;
  }
  return false;
};

// Helper function to unescape base64url encoding
function base64UrlUnescape(str) {
  str += Array(5 - str.length % 4).join('=');
  return str.replace(/-/g, '+') // Replace '-' with '+'
  .replace(/_/g, '/'); // Replace '_' with '/'
}