import _slicedToArray from "/usr/app/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import _createForOfIteratorHelper from "/usr/app/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import cookieServer from "cookie";
import cookieClient from "react-cookies";
export var getNumberWithOutPlus = function getNumberWithOutPlus(num) {
  return num === null || num === void 0 ? void 0 : num.replace("+966", "");
};
export var scrollToElement = function scrollToElement(element, scrollOffset) {
  var _el$getBoundingClient;
  var yOffset = scrollOffset;
  var el = element;
  var y = (el === null || el === void 0 ? void 0 : (_el$getBoundingClient = el.getBoundingClientRect()) === null || _el$getBoundingClient === void 0 ? void 0 : _el$getBoundingClient.top) + window.pageYOffset + yOffset;
  window.scrollTo({
    top: y
    //  behavior: "smooth"
  });
};
export function addSwipeEvents(objToSwipe, leftFunction, RightFunction) {
  if (objToSwipe) {
    var getTouches = function getTouches(evt) {
      return evt.touches ||
      // browser API
      evt.originalEvent.touches; // jQuery
    };
    var handleTouchStart = function handleTouchStart(evt) {
      var firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    };
    var handleTouchMove = function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }
      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;
      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;
      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          /* left swipe */
          document.querySelector("body").dir == "rtl" ? leftFunction() : RightFunction();
        } else {
          /* right swipe */
          //RightFunction();
          document.querySelector("body").dir == "rtl" ? RightFunction() : leftFunction();
        }
      } else {
        if (yDiff > 0) {
          /* up swipe */
        } else {
          /* down swipe */
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;
    };
    objToSwipe.addEventListener("touchstart", handleTouchStart, {
      passive: true
    });
    objToSwipe.addEventListener("touchmove", handleTouchMove, {
      passive: true
    });
    var xDown = null;
    var yDown = null;
  }
}
export var AddSwipeUp = function AddSwipeUp(objToSwipe, functions) {
  if (objToSwipe) {
    var getTouches = function getTouches(evt) {
      return evt.touches ||
      // browser API
      evt.originalEvent.touches; // jQuery
    };
    var handleTouchStart = function handleTouchStart(evt) {
      var firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    };
    var handleTouchMove = function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }
      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;
      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;
      if (yDiff > 0) {
        /* up swipe */
        functions();
      }

      /* reset values */
      xDown = null;
      yDown = null;
    };
    objToSwipe.addEventListener("touchstart", handleTouchStart, false);
    objToSwipe.addEventListener("touchmove", handleTouchMove, false);
    var xDown = null;
    var yDown = null;
  }
};
export var generateUniqueNumber = function generateUniqueNumber() {
  var min = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 100;
  var max = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10000;
  return Date.now() + "" + Math.floor(Math.random() * (max - min)) + min;
};
export var range = function range(min, max) {
  return Array.from({
    length: max - min + 1
  }, function (_, i) {
    return min + i;
  });
};
export function getDateThreeMonthsFromNow() {
  var currentDate = new Date();

  // Add three months to the current date
  currentDate.setMonth(currentDate.getMonth() + 3);

  // Format the date
  var year = currentDate.getFullYear();
  var month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  var day = currentDate.getDate().toString().padStart(2, "0");

  // Return the formatted date
  return "".concat(year, "-").concat(month, "-").concat(day);
}
export function normalizePrice(str) {
  if (typeof str !== "string") return null;

  // Replace commas with periods
  var formattedStr = str.replaceAll(",", "");

  // Parse the string as a floating-point number
  var number = parseFloat(formattedStr);
  return number;
}
export function parseJsonSafely(jsonString) {
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  try {
    var jsonData = JSON.parse(jsonString);
    if (jsonData === null || jsonData === undefined || jsonData === "null" || jsonData === "undefined") {
      return defaultValue;
    }
    return jsonData;
  } catch (e) {
    return defaultValue;
  }
}
export function capitalizeFirstLetter(string) {
  if (typeof string !== "string") return null;
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function generateYearsRangeList() {
  var endYear = new Date().getFullYear() + 1;
  var startYear = 2020;
  var yearsList = [];
  for (var x = startYear; x <= endYear; x++) {
    yearsList.push(x);
  }
  return yearsList;
}
export function extractPostId(text) {
  if (!text) return null;

  // Split the text by non-word characters
  var parts = decodeURIComponent(text).split(/[\W_]+/);
  var maxPostId = null;

  // Iterate through parts to find the maximum valid post ID
  var _iterator = _createForOfIteratorHelper(parts),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var part = _step.value;
      // Convert part to a number
      var num = Number(part);

      // Check if it's a valid number and not a year in the range 2006-2024
      if (!isNaN(num) && (num < 2006 || num > 2024)) {
        if (maxPostId === null || num > maxPostId) {
          maxPostId = num;
        }
      }
    }

    // Return the maximum valid post ID found, or null if none found
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return maxPostId;
}
export var getUserAgent = function getUserAgent(req) {
  if (req) {
    var _req$headers;
    return (req === null || req === void 0 ? void 0 : (_req$headers = req.headers) === null || _req$headers === void 0 ? void 0 : _req$headers["user-agent"]) || "N.A-SSR-user-agent";
  }
  if (typeof window !== "undefined") {
    return window.navigator.userAgent;
  }
  return "N.A-user-agent";
};
export var getUniversalCookieValue = function getUniversalCookieValue(req, cookieName) {
  var _req$headers2;
  var defaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var userCookie = req !== null && req !== void 0 && (_req$headers2 = req.headers) !== null && _req$headers2 !== void 0 && _req$headers2.cookie ? cookieServer.parse(req.headers.cookie) : null;
  return (userCookie === null || userCookie === void 0 ? void 0 : userCookie[cookieName]) || cookieClient.load(cookieName) || defaultValue;
};
export var createTimestampAndNextNDays = function createTimestampAndNextNDays(timestamp) {
  var date = new Date(timestamp * 1000);
  date.setHours(date.getHours() + 3);
  var monthNames = {
    en: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    ar: ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"]
  };
  var dayNames = {
    en: ["Sun", "Mon", "Tues", "Wednes", "Thurs", "Fri", "Satur"],
    ar: ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"]
  };
  var getDay = function getDay() {
    return date.getUTCDate();
  };
  var getMonth = function getMonth() {
    return date.getUTCMonth() + 1;
  };
  var getYear = function getYear() {
    return date.getUTCFullYear();
  };
  var getHours = function getHours() {
    return date.getUTCHours();
  };
  var getMinutes = function getMinutes() {
    return date.getUTCMinutes();
  };
  var getSeconds = function getSeconds() {
    return date.getUTCSeconds();
  };
  var getTime = function getTime() {
    var hours = date.getUTCHours().toString().padStart(2, '0');
    var minutes = date.getUTCMinutes().toString().padStart(2, '0');
    var seconds = date.getUTCSeconds().toString().padStart(2, '0');
    return "".concat(hours, ":").concat(minutes, ":").concat(seconds);
  };
  var getTimeForView = function getTimeForView() {
    var lang = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'en';
    var pm = 'PM';
    var am = 'AM';
    if (lang === 'ar') {
      pm = 'م';
      am = 'ص';
    }
    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes();
    var ampm = hours >= 12 ? pm : am;
    var hours12 = (hours % 12 || 12).toString().padStart(2, '0');
    var minutesPadded = minutes.toString().padStart(2, '0');
    return "".concat(hours12, ":").concat(minutesPadded, " ").concat(ampm);
  };
  var getMonthName = function getMonthName() {
    var lang = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'en';
    if (lang === 'ar') {
      return monthNames.ar[date.getUTCMonth()];
    }
    return monthNames.en[date.getUTCMonth()];
  };
  var getDayName = function getDayName() {
    var lang = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'en';
    var dayIndex = date.getUTCDay();
    if (lang === 'ar') {
      return dayNames.ar[dayIndex];
    }
    return dayNames.en[dayIndex];
  };
  var getNextMonthName = function getNextMonthName() {
    var lang = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'en';
    var nextMonth = (date.getUTCMonth() + 1) % 12;
    return lang === 'ar' ? monthNames.ar[nextMonth] : monthNames.en[nextMonth];
  };
  var getNextNDays = function getNextNDays(daysCount) {
    var availableDays = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var daysArray = [];
    var availableMap = new Map(availableDays.map(function (dayObj) {
      var _Object$keys = Object.keys(dayObj),
        _Object$keys2 = _slicedToArray(_Object$keys, 1),
        dateStr = _Object$keys2[0];
      return [dateStr, dayObj[dateStr]];
    }));
    var showNextMonth = false;
    var lastAvailableDayNextMonth = null;
    for (var i = 0; i < daysCount; i++) {
      var nextDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + i)); // Create a new Date object based on the initial UTC timestamp
      var dayString = nextDay.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
      var dayOfMonth = nextDay.getUTCDate();
      var monthNameEn = monthNames.en[nextDay.getUTCMonth()];
      var monthNameAr = monthNames.ar[nextDay.getUTCMonth()];
      var dayNameEn = dayNames.en[nextDay.getUTCDay()];
      var dayNameAr = dayNames.ar[nextDay.getUTCDay()];

      // Check if the day falls in the next month
      if (nextDay.getUTCMonth() !== date.getUTCMonth()) {
        showNextMonth = true;
        if (availableMap.has(dayString)) {
          lastAvailableDayNextMonth = dayOfMonth;
        }
      }
      daysArray.push({
        day: dayString,
        dayOfMonth: dayOfMonth,
        // Day of the month
        monthNameEn: monthNameEn,
        // Month name in English
        monthNameAr: monthNameAr,
        // Month name in Arabic
        dayNameEn: dayNameEn,
        // Day name in English
        dayNameAr: dayNameAr,
        // Day name in Arabic
        available: availableMap.has(dayString),
        // Mark as available if date exists in availableMap
        timestamp: availableMap.get(dayString) || null // Add timestamp if available
      });
    }
    return {
      days: daysArray,
      showNextMonth: showNextMonth,
      lastAvailableDayNextMonth: lastAvailableDayNextMonth
    };
  };
  return {
    day: getDay,
    month: getMonth,
    year: getYear,
    hours: getHours,
    minutes: getMinutes,
    seconds: getSeconds,
    time: getTime,
    monthName: getMonthName,
    dayName: getDayName,
    nextMonthName: getNextMonthName,
    nextNDays: getNextNDays,
    timeForView: getTimeForView
  };
};
export function alertSafe(message) {
  if (__IS_CSR__ && !__IS_PROD_ENV__) {
    alert(JSON.stringify(message));
  }
}
export function getPlatformInfo() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check if it is iOS
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  // Check if it is Android
  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // If it's neither iOS nor Android, assume it's Web
  return "Web";
  // usage   console.log(getPlatformInfo());
}
export function awaitTimeout(ms) {
  return new Promise(function (resolve) {
    return setTimeout(resolve, ms);
  });
}
export function awaitDomElement(selector) {
  return new Promise(function (resolve, reject) {
    var attempts = 0;
    var maxAttempts = 50;
    var interval = 200;
    var _checkForElement = function checkForElement() {
      var element = document.querySelector(selector);
      if (element) {
        resolve(element);
      } else if (attempts >= maxAttempts) {
        resolve(null);
      } else {
        attempts++;
        setTimeout(_checkForElement, interval);
      }
    };
    _checkForElement();
  });
}
export function generateYearsFrom(startYear) {
  var currentYear = new Date().getFullYear(); // Get the current year
  return Array.from({
    length: currentYear + 2 - startYear
  },
  // Add 2 to include both the next year and the starting year
  function (v, i) {
    return (currentYear + 1 - i).toString();
  });
}