export const ERRORS = "ERRORS";
export const SECONDARY_LOADING = "SECONDARY_LOADING";
export const POST_OFFLINE_ORDER = "POST_OFFLINE_ORDER";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const CREATE_ORDER = "CREATE_ORDER";
export const REFERRAL_ORDER = "REFERRAL_ORDER";
export const UPDATEFIELDS = "UPDATEFIELDS";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";
export const CONTACT_US_SUBMIT = "CONTACT_US_SUBMIT";
export const CONTACT_US_ERRORS = "CONTACT_US_ERRORS";
export const RESET_CHECKOUT_FIELDS = "RESET_CHECKOUT_FIELDS";
export const SET_TRAKING_ERRORS_MESSAGE = "SET_TRAKING_ERRORS_MESSAGE";
export const SHOW_TRACK_ORDER = "SHOW_TRACK_ORDER";
export const ORDER_CANCELED = "ORDER_CANCELED";
export const GET_CAR_INFO = "GET_CAR_INFO";
export const TRACKING_FORM = "TRACKING_FORM";
export const RESET_ORDER = "RESET_ORDER";
export const RESET_CAR_INFO = "RESET_CAR_INFO";
// FIXME: I AM NOT USED
export const GET_FINANCE_ORDER_DATA = "GET_FINANCE_ORDER_DATA";
export const SURVEY_DATA = "SURVEY_DATA";
export const SURVEY_ORDER_DATA = "SURVEY_ORDER_DATA";
export const SET_SURVEY_CODE = "SET_SURVEY_CODE";
export const GET_REMAINING_ORDER_DETAILS = "GET_REMAINING_ORDER_DETAILS";
export const GET_PAYLINK_ORDER_DETAILS = "GET_PAYLINK_ORDER_DETAILS";
//FIXME: I AM NOT USED
export const GET_FINANCE_REMAINING_ORDER_DETAILS =
  "GET_FINANCE_REMAINING_ORDER_DETAILS";
export const SADAD_INFORMATION = "SADAD_INFORMATION";
export const SADAD_ERROR = "SADAD_ERROR";
export const GET_NEW_SERVEY_DATA = "GET_NEW_SERVEY_DATA";
export const SET_SUBMIT_LOADING = "SET_SUBMIT_LOADING";
export const SHOW_FORM = "SHOW_FORM";
export const GET_TRACKING_CHECKOUT_ORDERS = "GET_TRACKING_CHECKOUT_ORDERS";
export const TRACKING_FINANCE_ORDERS = "TRACKING_FINANCE_ORDERS";
export const TRACKING_FINANCE_DETAILS = "TRACKING_FINANCE_DETAILS";
export const RESET_TRACKING_CHECKOUT_ORDERS = "RESET_TRACKING_CHECKOUT_ORDERS";
export const GET_ALL_OFFERED_DOC = "GET_ALL_OFFERED_DOC";
export const GET_TRACKING_STEP_DETAILS = "GET_TRACKING_STEP_DETAILS";
export const RESET_TRACKING_STEP_DETAILS = "RESET_TRACKING_STEP_DETAILS";


/* search  */
export const GET_SERVICE_CENTERS_DATA = "GET_SERVICE_CENTERS_DATA";
export const GET_SERVICE_CENTERS_RESULTS = "GET_SERVICE_CENTERS_RESULTS";

