import { useState, useEffect } from "react";
import Header from "./components/Header";
import Loader from "../../components/sharedComponents/Loader";
import stl from "./Layout.module.css";
import loadable from "@loadable/component";
import useIsFirstInteraction from "@hooks/useIsFirstInteraction";
import useIsWebView from "@hooks/useIsWebview";
import useIsMobile from "@hooks/useIsMobile";
import useIsIOS from "@hooks/useIsIOS";
import { CDN_LINK, ENABLE_PING_SECURITY } from "@globalConfig";
import useApi from "@hooks/useApi";
import { api__me } from "@/apis/customer/me";
import useFullLoading from "@hooks/useFullLoading";
import { useSession } from "@/_MiniApps/auth/AuthContext";
import { useCarCompareContext } from "@/_MiniApps/CarCompare/CarCompare";

let IS_LOADED_TIME = 1500;

const fallback = <>Loading...</>;
const NewAside = loadable(
    () => import("./components/NewAside/NewAside"),
    fallback
);
const MobileFooter = loadable(
    () => import("./components/MobileFooter/MobileFooter"),
    fallback
);
const Footer = loadable(() => import("./components/Footer"), fallback);
const NavBar = loadable(() => import("./components/NavBar/NavBar"), fallback);

const ContactUsPopUp = loadable(
    () => import("./components/ContactUsPopUp/ContactUsPopUp"),
    fallback
);

function Layout(props) {
    const { contact } = props;
    const loading = useFullLoading();
    const isWebView = useIsWebView();
    const isIos = useIsIOS();
    const isMobile = useIsMobile();
    const isFirstInteraction = useIsFirstInteraction();
    const isTracking = !!props.tracking;
    const { isLoggedIn } = useSession();
    const { hasCarsToCompare, isCompareBarAllowed } = useCarCompareContext();
    const [_, __, ___] = useApi([], () => api__me(isLoggedIn && ENABLE_PING_SECURITY));


    // Replicate componentDidMount
    useEffect(() => {
        setDocHeight();
        window.addEventListener("resize", setDocHeight);
        window.addEventListener("orientationchange", setDocHeight);


        return () => {
            window.removeEventListener("resize", setDocHeight);
            window.removeEventListener("orientationchange", setDocHeight);
        };
    }, []);


    const setDocHeight = () => {
        document.documentElement.style.setProperty(
            "--vh",
            `${window.innerHeight / 100}px`
        );
    };


    const [isLoaded, setIsLoaded] = useState(false);

    if (__IS_CSR__) {
        IS_LOADED_TIME = window.location.search.split('IS_LOADED_TIME=')?.[1] || IS_LOADED_TIME;
    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true);
        }, IS_LOADED_TIME);
    }, []);

    return (
        <>
            <div
                className={[
                    props.authenticationMsg ? stl.hasAuthPad : undefined,
                    props.hasNavBar ? stl.hasNavBar : undefined,
                    isWebView ? "webview" : "",
                    __IS_CSR__ && isLoaded ? "isLoaded" : "",
                    isTracking ? "isTracking" : "",
                    isIos ? "IOS" : "",
                    "MainContainerLO",
                ].join(" ")}
            >
                {!props.disableLoader && loading && <Loader />}
                {props.hasWebviewClose && (
                    <a className={"closeWebView leftCloseTest"} href="app://close">
                        <img
                            src={CDN_LINK + "/assets/images/closeBslider.svg"}
                            alt="close"
                        />
                    </a>
                )}

                <Header
                    setUnbxdPage={props.setUnbxdPage}
                    showSearchBar={props.showSearchBar}
                    hideBannerCssOnly={props.hideBannerCssOnly}
                    custommobileHeader={props.custommobileHeader}
                    custommobileHeaderFragmentOnly={props.custommobileHeaderFragmentOnly}
                    mobileHasBack={props.mobileHasBack}
                    homeSearchHeader={props.homeSearchHeader}
                    searchSearchHeader={props.searchSearchHeader}
                    deal={props.deal}
                    loading={loading}
                    hideAdvSearchMobile={props.hideAdvSearchMobile}
                    headHideLinks={props.headHideLinks}
                    headHideLinksMobile={props.headHideLinksMobile === undefined ? true : props.headHideLinksMobile}
                    mobileHasTitle={props.mobileHasTitle}
                    mobileNoLogo={props.mobileNoLogo}
                    mobileNoLogoHasBack={props.mobileNoLogoHasBack}
                    notification={props.notification}
                    resetSearchFilters={props.resetSearchFilters}
                    hideOrangeHeadBtn={props.hideOrangeHeadBtn}
                    burgrMenuisCncl={props.burgrMenuisCncl}
                    logoNotClickable={props.logoNotClickable}
                    hideMenu={props.hideMenu}
                    revampedHEading={props.revampedHEading}
                    revampedSearch={props.revampedSearch}
                    tracking={props.tracking}
                    contact={contact}
                    linksForSearch={props.linksForSearch}
                    hideSideMenue={props.hideSideMenue}
                    hideAppBanner={props.hideAppBanner}
                    hideBurgerMenu={props.hideBurgerMenu}
                    hideHeaderOnmobile={props.hideHeaderOnmobile}
                    straightHeader={props.straightHeader}
                />

                <main
                    className={[
                        props.mainClass ? props.mainClass : undefined
                    ].join(" ")}
                >
                    {props?.children}
                </main>
                {!isMobile && !props.hideFooter && (
                    <Footer
                        hideFooterWithoutAuthMsg={props.hideFooterWithoutAuthMsg}
                        authenticationMsg={props.authenticationMsg}
                    />
                )}

                {isMobile && props.hasMobFooter && (
                    <div className="m-show">
                        <MobileFooter />
                    </div>
                )}


                {hasCarsToCompare() && isCompareBarAllowed() ? <></> : isMobile && props.hasNavBar && <NavBar customStikeyButton={props.customStikeyButton} {...props} />}
            </div>

            <NewAside
                switchLink={props.switchLink}
            />

            <div id="popUpId" />

            {props.hasContactUs && isFirstInteraction && (
                <ContactUsPopUp {...props} />
            )}
        </>
    );
}

export default Layout;
