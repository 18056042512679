import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Post View
var TRACKING_POST_PARAMS = ['product_position', 'listing_type', 'page_number', 'is_rec', 'bigGallView', 'logged_in'];
// Listing
var TRACKING_LISTING_PARAMS = ['referral_location', 'browse_variant', 'variant_value'];
var excludedPaths = ["/report/", "/dashboard/", "/admin/"];
var shouldNavigate = __IS_CSR__ ? !excludedPaths.some(function (path) {
  return window.location.pathname.indexOf(path) !== -1;
}) : true;
export var getAndClearTrackingReferralParams = function getAndClearTrackingReferralParams() {
  var clearItems = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  var trackingReferralParams = {};
  if (__IS_SSR__) {
    return trackingReferralParams;
  }
  [].concat(TRACKING_POST_PARAMS, TRACKING_LISTING_PARAMS).forEach(function (key) {
    var value = localStorage.getItem(key);
    if (value) {
      trackingReferralParams[key] = value;
      if (clearItems) {
        localStorage.removeItem(key); // Clear the item from localStorage
      }
    }
  });
  return trackingReferralParams;
};
var useHashToLocalStoreEvent = function useHashToLocalStoreEvent() {
  var _window2, _window2$location;
  if (__IS_SSR__) {
    return;
  }
  var location = useLocation();
  var navigate = useNavigate();
  useEffect(function () {
    var _window;
    if (!navigate || !((_window = window) !== null && _window !== void 0 && _window.location)) return;
    var handleHashChange = function handleHashChange() {
      if (location.hash) {
        var hash = location.hash.slice(1); // Remove the leading '#'
        var params = new URLSearchParams(hash);
        console.log(hash, 'hash');
        [].concat(TRACKING_POST_PARAMS, TRACKING_LISTING_PARAMS).forEach(function (key) {
          var value = params.get(key);
          if (value) {
            localStorage.setItem(key, value);
          }
        });

        // Remove the hash from the URL without triggering a page reload
        // also include the query string if any
        if (shouldNavigate) {
          navigate(window.location.pathname + window.location.search, {
            replace: true
          });
        }
      }
    };
    handleHashChange(); // Call once on mount and when location changes

    // Cleanup function
    return function () {
      // No cleanup needed for this hook
    };
  }, [(_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$location = _window2.location) === null || _window2$location === void 0 ? void 0 : _window2$location.href, navigate]);
};
export default useHashToLocalStoreEvent;