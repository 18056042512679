import cookieClient from "react-cookies/build/cookie";
import { SECURE_COOKIE } from "@globalConfig";
var COOKIES_CONFIGS = {
  path: "/",
  maxAge: 3600 * 24 * 365 * 10,
  sameSite: true,
  secure: SECURE_COOKIE
};
export function cookie__setAccessToken(token) {
  cookieClient.save("accessToken", token || '', COOKIES_CONFIGS);
}
export function cookie__setUserId(userId) {
  cookieClient.save("userId", userId ? userId : '', COOKIES_CONFIGS);
}