import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "@i18n";
import { CDN_LINK } from "@globalConfig";
import stl from "./SearchByMakeContainer.module.css";
import SubmitButton from "../../../../../components/UiKit/Buttons/SubmitButton/SubmitButton";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
import useHelpers from "@hooks/useHelpers";
import {useLang} from "@/common/Contexts/LanguageContext";

const SearchByMakeContainer = (props) => {
  const { t } = useTranslation(["web_home"]);
  const { createUrl } = useHelpers();
  const [showAllMakes, setShowAllMakes] = useState(false);
  const lang = useLang();
  const toggleAllMakes = () => {
    setShowAllMakes(!showAllMakes);
  };

  const createLink = (item) => {
    if (props.isNew) {
      return createUrl(
        `/filters`,
        {
          make_id: item.id,
          ["condition_id"]: 1,
        },
        {
          referral_location: "Home",
          browse_variant: "make",
          variant_value: item.name_en,
        }
      );
    }

    return createUrl(
      `/autos/${item?.name_en?.slugify()}`,
      {},
      {
        referral_location: "Home",
        browse_variant: "make",
        variant_value: item.name_en,
      }
    );
  };

  return (
    <>
      <section className={`${stl.brands_container}`}>
        <h2 className={`container big`}>
          {t("تصفح السيارت حسب الماركة", { ns: "web_home" })}
        </h2>
        <div className={`${stl.brands_makesContainer} ${stl.makesWithLogo}`}>
          {/* desktop only, the first 8 brands with images only */}
          {props?.carMakes?.slice(0, 8)?.map((make, idx) => {
            return (
              <Link
                key={idx}
                to={createLink(make)}
                state={{ search_type: "homepage_listing" }}
                className={` ${stl.singleLogoMake}`}
                aria-label={make.name}
              >
                <div className={stl.imgContainer}>
                  <ProgressiveImage
                    waitUntilInteractive
                    fetchpriority="low"
                    width={90}
                    height={90}
                    src={make.logo}
                    alt={make.name}
                    loading="lazy"
                  />
                </div>
                <h3 className={stl.makeName}>{make.name}</h3>
              </Link>
            );
          })}
          {/* mobile only, the all brands with images starting from the 9th brand */}
          {props?.carMakes?.slice(8)?.map((make, idx) => {
            return (
              <Link
                key={idx}
                to={createLink(make)}
                state={{ search_type: "homepage_listing" }}
                className={` ${stl.singleLogoMake} m-show`}
                aria-label={make.name}
              >
                <div className={stl.imgContainer}>
                  <ProgressiveImage
                    waitUntilInteractive
                    fetchpriority="low"
                    width={90}
                    height={90}
                    src={make.logo}
                    alt={make.name}
                    loading="lazy"
                  />
                </div>
                <h3 className={stl.makeName}>
                    {lang === "ar" && (make.name_ar || make.name_ara || make.name)}
                    {lang === "en" && (make.name_en || make.name_eng || make.name)}
                </h3>
                </Link>
            );
          })}
          <Link
            to={props.moreLink}
            className={["m-show", stl.moreLink].join(" ")}
          >
            <span>{t("جميع السيارات", { ns: "web_home" })}</span>
            <img
              src={CDN_LINK + "/assets/images/arrMore.png"}
              fetchpriority="low"
              alt="image"
              width={"15px"}
              height={"19px"}
            />
          </Link>
        </div>
        {!showAllMakes && (
          <SubmitButton
            onClick={() => {
              toggleAllMakes();
            }}
            className={["whtBtn", "m-hide", stl.viewAllMakes, stl.button].join(
              " "
            )}
            type="button"
            loading={false}
            disabled={false}
          >
            {t("شاهد جميع الماركات", { ns: "web_home" })}
          </SubmitButton>
        )}
        {/* desktop only, the all brands without images  starting from the 9th brand  */}
        {showAllMakes && (
          <div className={`${stl.allCarsMakeText}`}>
            <h3>{t("جميع الماركات", { ns: "web_home" })}</h3>
            <ul>
              {props?.carMakes?.slice(8)?.map((item, idx) => {
                return (
                  <li key={idx}>
                    <Link to={createLink(item)}>{`${item.name}`}</Link>
                  </li>
                );
              })}
              <li>
                <Link className={stl.underLine} to={props.moreLink}>
                  {t("جميع السيارات", { ns: "web_home" })}
                </Link>
              </li>
            </ul>
          </div>
        )}
      </section>
    </>
  );
};

export default SearchByMakeContainer;
